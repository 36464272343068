






































































import SocialpostTopicSelect from "@/components/admin/socialpost-topics/SocialpostTopicSelect.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({ components: { SocialpostTopicSelect } })
export default class WritePost extends Vue {
  chatTypes = [
    { value: "slido", text: "Slido" },
    { value: "vimeo", text: "VimeoChat" },
    { value: "embedded-chat", text: "EmbeddedChat" },
  ];

  @Prop()
  content!: any;

  @Prop()
  settings!: any;

  ChatURLPrefix(type) {
    let url;
    switch (type) {
      case "vimeo":
        url = "https://vimeo.com/event/";
        break;
      case "slido":
        url = "https://app.sli.do/event/";
        break;
      case "embedded-chat":
        url = null;
        break;
      default:
        url = "No livestream platform specified";
    }
    return url;
  }

  get languages() {
    return this.$store.state.Languages.all;
  }
}
