<template>
  <b-overlay :show="isLoading">
    <b-row class="text-center" align-v="center">
      <b-col class="text-left">
        <h1>{{ $t("homePage") }}</h1>
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert
          align-v="center"
          variant="warning"
          :show="saveMessage !== ''"
          >{{ this.saveMessage }}</b-alert
        >
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button variant="primary" :disabled="saveDisabled" @click="saveAll()">
          {{ $t("saveAll") }}
        </b-button>
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab :title="this.$i18n.t('intro')">
        <IntroConfig v-if="content.intro" :content="content.intro" />
      </b-tab>
      <b-tab :title="this.$i18n.t('content')">
        <ContentConfig v-if="content.blocks" :object="content.blocks" />
      </b-tab>
      <b-tab :title="this.$i18n.t('interaction')">
        <InteractionConfig
          v-if="content.interaction && user.role !== 'moderator'"
          :content="content.interaction"
        />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>
<script>
import IntroConfig from "@/components/admin/home/IntroConfig.vue";
import ContentConfig from "@/components/admin/home/ContentConfig.vue";
import InteractionConfig from "@/components/admin/home/InteractionConfig.vue";

export default {
  components: {
    IntroConfig,
    ContentConfig,
    InteractionConfig,
  },
  data() {
    return {
      isLoading: false,
      active: true,
      saveDisabled: true,
      saveMessage: "",
    };
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch("Dash/fetchAll");
    for (const block of this.content.blocks) {
      if (block.title instanceof Array) {
        block.title = {};
      }
      if (block.description instanceof Array) {
        block.description = {};
      }
      if (block.blocks) {
        for (const subblock of block.blocks) {
          if (subblock.title instanceof Array) {
            subblock.title = {};
          }
          if (subblock.description instanceof Array) {
            subblock.description = {};
          }
          if (subblock.category instanceof Array) {
            subblock.category = {};
          }
        }
      }
      if (block.slides) {
        for (const subblock of block.slides) {
          if (subblock.title instanceof Array) {
            subblock.title = {};
          }
          if (subblock.description instanceof Array) {
            subblock.description = {};
          }
          if (subblock.category instanceof Array) {
            subblock.category = {};
          }
        }
      }
    }
    this.saveDisabled = true;
    this.saveMessage = "";
    this.isLoading = false;
  },
  watch: {
    content: {
      handler() {
        this.saveMessage = this.$i18n.t("unsavedChanges");
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  computed: {
    content() {
      return this.$store.state.Dash.content;
    },
    settings() {
      return this.$store.state.Config.all;
    },
    user() {
      return this.$store.getters["Auth/currentUser"];
    },
  },
  methods: {
    async saveAll() {
      this.isLoading = true;
      await this.$store.dispatch("Dash/update", this.content);
      await this.$store.dispatch("Dash/fetchAll");
      // await this.$store.dispatch("Config/updateSetting", this.settings);
      this.$root.$bvToast.toast("Home settings saved.", { variant: "success" });
      this.saveDisabled = true;
      this.saveMessage = "";
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);

.language-tab {
  ul {
    color: $color-primary;
  }
}

.save-button {
  margin-top: 15px;
}
</style>
<style lang="scss">
.language-tab {
  .nav-link {
    padding: 2px 2px !important;
  }
}

.no-padding {
  a {
    padding: 0px !important;
  }
}

.content-form {
  padding-top: 15px;
}

.content-switch {
  margin-bottom: 15px;
}

.component-switch {
  margin-left: 15px;
}
</style>
